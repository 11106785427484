<script>
import PosFlagsService from '@src/services/PosFlagsService'
import importModal from '@views/importJob/importJobModal'
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue';
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import Layout from '@layouts/main.vue'
import FilterBar from '@src/components/FilterBar'
import posFlagModal from '@views/posFlag/posFlagModal.vue'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import pagination from '@src/mixins/pagination.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import ImportButton from '@/src/components/ImportButton.vue'
import ExportButton from '@/src/components/ExportButton.vue'
import EditTableButton from '@/src/components/EditTableButton.vue'
import DeleteTableButton from '@/src/components/DeleteTableButton.vue'
import { debounce } from 'lodash'

const i18nKey = 'POS'
const i18nCommon = 'COMMON'

export default {
    components: {
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        Layout,
        posFlagModal,
        ConfirmationFormModal,
        FeedbackModal,
        FilterBar,
        importModal,
        AddLabelButton,
        ImportButton,
        ExportButton,
        EditTableButton,
        DeleteTableButton,
    },
    mixins: [pagination, swalFeedback],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            i18nCommon,
            i18nKey,
            perPage: 10,
            fields: [
                {
                    name: 'name',
                    title: this.getI18n(i18nKey, 'TITLES.flag'),
                    sortField: 'name',
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            filterText: '',
            additionalParameters: {},
        }
    },
    metaInfo() {
        return {
            title: this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.flag',
                modifier: 2,
            }),
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: 'POS',
                        suffix: 'TITLES.pos',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.flag',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nNewPosFlag() {
            return this.getI18n(i18nKey, 'BUTTONS.new_flag')
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters.search = ''
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    methods: {
        fetch(url, params) {
            return PosFlagsService.fetchVuetable(url, params)
        },
        createAdditionalParameters() {
            this.additionalParameters.search = ''
            if (this.filterText && this.filterText.length > 0) {
                this.additionalParameters.search = `name:${this.filterText}`
            }
            return this.additionalParameters
        },
        showImportModal() {
            this.$nextTick(() => this.$refs.importModal.showModal())
        },
        i18nPosFlag(modifier) {
            return this.getI18nModified({
                prefix: i18nKey + '.TITLES.flag',
                modifier: modifier,
            })
        },
        showModal(data) {
            this.$nextTick(() => this.$refs.posFlagModal.showModal(data))
        },
        openModalToCreate() {
            this.showModal()
        },
        openModalToEdit(data) {
            this.showModal(data)
        },
        reloadTable() {
            this.$refs.vuetable.reload()
        },
        refreshTable() {
            this.$refs.vuetable.refresh()
        },
        handleDelete(id) {
            this.$refs.confirmationFormModal.showModal(false, this.deletePosFlag, id)
        },
        async deletePosFlag(deleteRecord, id) {
            if (!deleteRecord) {
                return;
            }

            if (id > 0) {
                const response = await PosFlagsService.delete(id)
                    .then((response) => response)
                    .catch((error) => error)

                const statusCode = response.status.toString()

                if (statusCode.charAt(0) === '2') {
                    this.positiveFeedback(this.i18nPosFlag(1), 'deleted')
                    this.refreshTable()
                } else if (statusCode === '417') {
                    this.$refs.feedbackModal.showModal(statusCode, response.data.message)
                } else {
                    this.negativeFeedback()
                }
            }
        },
    },
}
</script>
<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText"></filter-bar>
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-label-button
                            v-if="$can('CREATE', 'POSFLAG')"
                            :title="i18nNewPosFlag"
                            @onClick="openModalToCreate"
                        />
                        <import-button
                            v-if="$can('CREATE', 'IMPORTJOB')"
                            @onClick="showImportModal"
                        />
                        <export-button
                            v-if="$can('CREATE', 'EXPORTJOB')"
                            type="point_of_sale_flag"
                            :create-parameters="createAdditionalParameters"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nPosFlag(2)" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :display-selection-records-per-page="true"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <edit-table-button v-if="$can('UPDATE', 'POSFLAG')" @onClick="openModalToEdit(props.rowData)" />
                                <delete-table-button v-if="$can('DELETE', 'POSFLAG')" class="ml-1" @onClick="handleDelete(props.rowData.id)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <pos-flag-modal
            ref="posFlagModal"
            @refresh="reloadTable"
        />
        <import-modal
            ref="importModal"
            :csv-type="'point_of_sale_flag'"
            :title="
                getI18nModified({
                    prefix: 'IMPORT_JOBS',
                    suffix: 'TYPES.point_of_sale_flag',
                    modifier: 2,
                })
            "
        />
        <confirmation-form-modal
            ref="confirmationFormModal"
            :title="i18nPosFlag(1)"
            :operation="'deleted'"
        />
        <feedback-modal
            ref="feedbackModal"
            :title="i18nPosFlag(1)"
        />
    </Layout>
</template>
